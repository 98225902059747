// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import usedIcons from '../../data/icons.js'
interface IconProps {
    name: string
    page: string
  }
function Icon({ page, name }: IconProps) {
    const icons = usedIcons
    return (
        <FontAwesomeIcon icon={icons[page][name]} size={name == 'none' ? 'xs' : 'xl'} className='w-8' id={name} />
    )
  }
export default Icon