import {
    faGamepad, faLandmark, faBullseye, 
    faCrosshairs, faGem, faStairs, faRepeat, 
    faHourglassStart, faGaugeHigh, faTrophy, 
    faPersonChalkboard, faScroll, faPersonSkating, 
    faChartBar, faCloudMoon, faGift, faUnlock, 
    faArrowUp91, faRuler, faBinoculars, 
    faFont, faPeopleGroup, faBars, faCircle, faEnvelope
} from '@fortawesome/free-solid-svg-icons'
// faListUl faRoute, faClipboardList, faFileLines

export default {
    aboutDraft1 : {
        overview: faBinoculars,
        summary: faFont,
        example: faPersonChalkboard,

        pursuit: faPersonSkating,
            goal: faBullseye,
            aim: faCrosshairs,
            objective: faStairs,
            habit: faRepeat,

        action: faGamepad,
            mission: faHourglassStart,
            challenge: faGaugeHigh,
            achievement: faTrophy,
            quest: faScroll,

        progress: faChartBar,

        rewards: faGem,
            gifts: faGift,
            unlocks: faUnlock,
            levels: faArrowUp91,
            tracking: faRuler,
        
        principle: faLandmark,
        dream: faCloudMoon,
        team: faPeopleGroup,

        toc: faBars,
        none: faCircle
    },
    main: {
        mail: faEnvelope
    }
}
