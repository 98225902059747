//#region   //*     # notes
/* //! ------------------------------------------------------------- !\\
* NOTES

*/ //! ------------------------------------------------------------- !\\
//#endregion
//#region   //*     # preview

import Waitlist from "./Waitlist"
import Icon from "../components/partials/Icon"

function Preview({active, setActive}) {
    const contact = 'pete@pursu.app'

    return (
        <div className='m-2 mt-8'>
            <div className='flex justify-end'>
                <div className="badge badge-primary badge-lg place fixed top-2 right-2 p-3 pb-4 border border-neutral-content cursor-pointer" onClick={() => setActive('waitlist')}>x</div>
            </div>
            <div className='m-4 p-4 border rounded-lg border-slate-500'>
                <p>Pursu is a game designed around following your own real-world ambitions.</p>
                <p>Use the AI-powered framework to define your 'pursuits' and have these converted to actions.</p>
                <p>Complete actions to earn rewards and level up.</p>
            </div>
            <div className='m-4 p-4 border rounded-lg border-slate-500'>
                <ul className="steps steps-vertical">
                    <li className="step step-primary">define a pursuit</li>
                    <li className="step step-primary">generate actions</li>
                    <li className="step step-primary">track progress</li>
                    <li className="step step-primary">earn rewards</li>
                </ul>
            </div>
            <div className='m-4'>
                <Waitlist active={active} setActive={setActive}/>
                <div role="alert" className="alert border border-neutral max-w-fit fixed bottom-0 right-16 !py-2 !ps-4 m-1">
                    <span>
                        <Icon page={'main'} name={'mail'} />
                        <span className='ms-2'>{contact}</span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Preview
//#endregion