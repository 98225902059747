/* eslint-disable */
/* @ts-nocheck
/* //! ------------------------------------------------------------- !\\
* NOTES
TODO
    - reinstate eslint/ts
    - connect waitlist field to BE/DB store (with date of entry)

*/ //! ------------------------------------------------------------- !\\

import { useState, useEffect } from "react"
import axios from "axios"
function setAPI() {
    process.env.REACT_APP_NODE_ENV == 'development' 
        ?   axios.defaults.baseURL = 'http://localhost:'+process.env.REACT_APP_API_PORT 
        :   axios.defaults.baseURL = String(process.env.REACT_APP_API)
} setAPI()

function Waitlist({ active, setActive }) {
    //* # email
    const [email, setEmail] = useState('')
    const handleEmail = (e) => { setEmail(e.target.value) }
    //* # register
    const register = () => validate()
    //* ## validate
    const [isValid, setIsValid] = useState(null)
    const validate = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        setIsValid(emailRegex.test(email))
    }
    useEffect(() => { isValid ? send() : null }, [isValid])
    //* ## send
    const send = async () => {
        axios.post('/auth/waitlist', { email })
        .then(res => res.status === 200 ? setSent(true) : null)
    }
    //* ## sent
    const [sent, setSent] = useState(false)

    return (
        <div id='component' className=" max-sm:max-w-screen max-sm:mx-2 mb-24 | border-2 border-neutral rounded-lg | shadow-lg shadow-neutral "> 
            <div id='title' className="flex justify-between m-4">
                <div>
                    <span className='text-5xl font-bold text-purple-900'>Pursu</span>
                    <span className="badge badge-secondary ms-2 mt-1 align-top">beta</span>
                    <p className="mt-2 pt-1 ms-8 ps-1 italic">play life</p>
                </div>
                { active == 'waitlist' ? 
                    <div className="badge badge-neutral badge-lg place mt-3 p-3 cursor-pointer" onClick={() => setActive('preview')}>?</div>
                : null }
            </div>
            <div id='form' className='p-6 max-w-fit flex flex-col text-lg'>
                <p className="my-4 ms-1">Join the waitlist, stay informed, earn early adopters rewards.</p>
                { email && isValid != null && isValid === false ? <p className="text-error ps-4 py-1">Invalid email</p> : null }
                { sent ? 
                    <div id='sent' className="alert alert-success flex h-[52px] mt-2" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        <span>You're on the waitlist!</span>
                    </div> 
                : 
                    <div id='send' className='join mt-2 h-[58px]'>
                        <input className="input max-sm:!w-full input-bordered !outline-offset-0 join-item" type="text" placeholder="Email..." 
                            onKeyUp={e => handleEmail(e)}/>
                        <button className="btn btn-primary join-item" onClick={() => register()}>Register</button>
                    </div>
                }
            </div>
        </div>
    )
}

function Countdown() {
    //* # countdown
    // Set your target date here
    const targetDate = new Date('2024-01-10T00:00:00Z');

    // Calculate initial time difference
    const calculateTimeDifference = () => {
        const now = new Date();
        const difference: number = targetDate.getTime() - now.getTime();
        const days: number = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours: number = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes: number = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds: number = Math.floor((difference % (1000 * 60)) / 1000);
        return {
        days,
        hours,
        minutes,
        seconds,
        };
    };
    const [time, setTime] = useState(calculateTimeDifference())
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(calculateTimeDifference())
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    return (
        <div className="grid mt-24 mb-16 justify-center grid-flow-col gap-5 text-center auto-cols-max">
            <div className="flex flex-col">
                <span className="countdown font-mono text-6xl">
                <span style={{ '--value' : time.days } as React.CSSProperties}>{time.days}</span>
                </span>
                days
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-6xl">
                <span style={{ '--value' : time.hours } as React.CSSProperties}>{time.hours}</span>
                </span>
                hours
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-6xl">
                <span style={{ '--value' : time.minutes } as React.CSSProperties}>{time.minutes}</span>
                </span>
                min
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-6xl">
                <span style={{ '--value' : time.seconds } as React.CSSProperties}>{time.seconds}</span>
                </span>
                sec
            </div>
        </div>
    )
}

export default Waitlist