/* eslint-disable */
// @ts-nocheck
//#region   //*     # notes
/* //! ------------------------------------------------------------- !\\
* NOTES

TODO

- implement 'layout' usage
    - trial on /about/team (want to have topbar on that route, but not when component is nested in /about, AND call the component directly in each)
- setup Prettier
- utilise react-app-env.d.ts type setting
- standardise custom theme
- add phone nav component (to layout component) - https://daisyui.com/components/bottom-navigation/
- utilise daisyUI skeleton components for AI call loading https://daisyui.com/components/skeleton/
    
! urgent : important

! urgent : not-important

! not-urgent : important

! not-urgent : not-important

*/ //! ------------------------------------------------------------- !\\
//#endregion
//#region   //*     # index
//#region   //*         ## imports
//#region   //*             ### main
import * as ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
//#endregion
//#region   //*             ### components
import About from './pages/About'
import Create from './pages/Create'
import Demo from './pages/Demo'
import Landing from './pages/Landing'
// import Preview from './pages/Preview'
// import Waitlist from './pages/Waitlist'
import MVP from './pages/MVP'
import Team from './pages/Team'
//#endregion
//#region   //*             ### content
import about from './data/about'
import onboarding from './data/onboarding'
//#endregion
//#endregion
//#region   //*         ## build
//#region   //*             ### root
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
document.documentElement.setAttribute('data-theme', 'night')
document.documentElement.className = 'scrollbar-hide bg-base-200'
//#endregion
//#region   //*             ### router
root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<MVP/>} />
            {/* <Route path='/preview' element={<MVP />}/> */}
            {/* <Route path="/landing" element={<Landing/>}/>
            <Route path='/about/product' element={<About content={about} page='aboutDraft1' isSlides={false}/>}/>
            <Route path='/about/project' element={<About content={onboarding} page='aboutDraft1' isSlides={true}/>}/>
            <Route path='/about/team' element={<Team/>}/>
            <Route path='/demo' element={<Demo mode="demo"/>}/>
            <Route path='/create' element={<Create/>}/> */}
        </Routes>
    </BrowserRouter>
)
//#endregion
//#endregion
//#endregion